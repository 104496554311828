import { StrictMode } from 'react';
import 'product_modules';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import {
  googlePlacesApi,
  productCalculationsApi,
  adminAuthApi,
  recaptchaApi,
} from 'store/api';
import 'product_modules';
import './index.scss';
import ApiProvider, { IApiProviderValue } from 'providers/ApiServiceProvider';
import SettingsCountryProvider from 'providers/SettingsCountryProvider';
import LoadersProvider from 'product_modules/providers/LoadersProvider';
import GoogleAnalyticsProvider from 'providers/GoogleAnalyticsProvider';
import { VariablesBySystemNameLoader } from 'product_modules/loaders/Variables/VariablesBySystemNameLoader';
import AppTheme from 'AppTheme';
import ErrorBoundary from 'ErrorBoundary';
import LocalizationProvider from 'providers/LocalizationProvider';
import EntryRouter from 'routes/entry-router';
import CustomCssConfigProvider from 'providers/CustomCssConfigProvider';
import RecaptchaProvider from 'providers/RecaptchaProvider';
import TestModeNotificationsProvider from 'providers/TestModeNotificationsProvider';

const apiProviderValue: IApiProviderValue = {
  googlePlacesApi,
  productCalculationsApi,
  adminAuthApi,
  recaptchaApi,
};

const loaders = [{
  component: VariablesBySystemNameLoader,
}];

ReactDOM.render(
  <ErrorBoundary>
    <StrictMode>
      <Provider store={store}>
        <ApiProvider value={apiProviderValue}>
          <GoogleAnalyticsProvider>
            <RecaptchaProvider>
              <SettingsCountryProvider>
                <LoadersProvider loaders={loaders}>
                    <CustomCssConfigProvider>
                      <AppTheme>
                        <LocalizationProvider>
                          <TestModeNotificationsProvider>
                            <EntryRouter />
                          </TestModeNotificationsProvider>
                        </LocalizationProvider>
                      </AppTheme>
                    </CustomCssConfigProvider>
                </LoadersProvider>
              </SettingsCountryProvider>
            </RecaptchaProvider>
          </GoogleAnalyticsProvider>
        </ApiProvider>
      </Provider>
    </StrictMode>
  </ErrorBoundary>,
  document.getElementById('root'),
);
