import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ITestModeNotificationItem } from 'components/TestModeNotificationList/TestModeNotificationList';

type ITestModeNotifications = Record<string, ITestModeNotificationItem>;

interface IPortalElementsValidationProviderValue {
  testModeNotifications: ITestModeNotifications;
  registerTestModeNotification: (elementId: string, notification: ITestModeNotificationItem) => void;
  unregisterTestNodeNotification: (elementId: string) => void;
}

const TestModeNotificationsContext = createContext<IPortalElementsValidationProviderValue>({
  testModeNotifications: {},
  registerTestModeNotification: () => {},
  unregisterTestNodeNotification: () => {},
});

export const useTestModeNotifications = () => {
  const { testModeNotifications } = useContext(TestModeNotificationsContext);

  return testModeNotifications;
};

export const useRegisterTestModeNotification = (
  elementId: string,
  getNotification: () => ITestModeNotificationItem | null,
  dependencies: unknown[] = [],
) => {
  const { registerTestModeNotification, unregisterTestNodeNotification } = useContext(TestModeNotificationsContext);

  useEffect(() => {
    const notification = getNotification();

    if (notification) {
      registerTestModeNotification(elementId, notification);
    }

    return () => {
      unregisterTestNodeNotification(elementId);
    };
  }, [elementId, ...dependencies]);
};

const TestModeNotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [
    testModeNotifications,
    setTestModeNotifications,
  ] = useState<ITestModeNotifications>({});

  const registerTestModeNotification = useCallback((elementId: string, notification: ITestModeNotificationItem) => {
    setTestModeNotifications((prevState) => ({
      ...prevState,
      [elementId]: notification,
    }));
  }, []);

  const unregisterTestNodeNotification = useCallback((elementId: string) => {
    setTestModeNotifications((oldElementsValidationResults) => {
      const { [elementId]: _, ...rest } = oldElementsValidationResults;

      return rest;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      testModeNotifications,
      registerTestModeNotification,
      unregisterTestNodeNotification,
    }),
    [testModeNotifications, registerTestModeNotification, unregisterTestNodeNotification],
  );

  return (
    <TestModeNotificationsContext.Provider value={contextValue}>{children}</TestModeNotificationsContext.Provider>
  );
};

export default TestModeNotificationsProvider;
